<template>
    <div class="adopt-contract">
        <div class="el-content mb12">
            <a-space>
                <a-input-search
                    v-model:value="search.key"
                    style="width:300px"
                    placeholder="请输入合同名称进行查询"
                    enter-button
                    @search="getContractList(1,info.limit)"
                />
                <router-link to="/adopt/contract_edit">
                    <a-button type="primary"><i class="ri-add-line"></i>新增合同</a-button>
                </router-link>
            </a-space>
        </div>
        <div class="el-content">
            <a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
                {title:'ID',dataIndex:'id'},
                {title:'合同名称',dataIndex:'name'},
                {title:'甲方',dataIndex:'jia',slots:{customRender:'jia'}},
                {title:'乙方',dataIndex:'party_b'},
                {title:'排序',dataIndex:'sort'},
                {title:'修改时间',dataIndex:'update_time'},
                {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
            ]">
                <template #jia="{record}">
                    <div v-for="(item,index) in record.owner" :key="index">
                        {{item.key}} : {{item.value}}
                    </div>
                </template>
                <template #action="{record}">
                    <a-space>
                        <router-link :to="{path:'/adopt/contract_edit',query:{id:record.id}}">
                            <kd-button type="primary" icon="ri-edit-line" title="编辑"></kd-button>
                        </router-link>
                        <kd-button type="success" 
                            icon="ri-file-list-3-line" 
                            title="合同记录" 
                            @click="showRecord(record.id)">
                        </kd-button>
                    </a-space>
                </template>
            </a-table>
            <div class="pager">
				<a-pagination
					show-size-changer
					:default-current="info.page"
					:total="info.count"
					@showSizeChange="(p,e)=>{getContractList(info.page,e)}"
					@change="(e)=>{getContractList(e,info.limit)}"
				/>
			</div>
        </div>

        <a-modal v-model:visible="show.record" title="合同记录" :footer="null" width="900px" @cancel="show.record= false">
            <a-table :pagination="false" row-key="id" :data-source="contract.list" :columns="[
                {title:'ID',dataIndex:'id'},
                {title:'签订项目',dataIndex:'name'},
                {title:'签订对象',dataIndex:'user',slots:{customRender:'user'}},
                {title:'签订时间',dataIndex:'create_time'},
                {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
            ]">
                <template #user="{record}">
                    {{record.nickname}}（{{record.mobile}}）
                </template>
                <template #action="{record}">
                    <a-space>
                        <kd-button type="success" 
                            icon="ri-download-line" 
                            title="下载合同" 
                            @click="downloadContract(record.order_id)">
                        </kd-button>
                    </a-space>
                </template>
            </a-table>
            <div class="pager">
				<a-pagination
					show-size-changer
					:default-current="contract.page"
					:total="contract.count"
					@showSizeChange="(p,e)=>{getContractRecord(contract.page,e)}"
					@change="(e)=>{getContractRecord(e,contract.limit)}"
				/>
			</div>
        </a-modal>
    </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import adoptModel from '@/api/adopt'
export default {
    setup() {
        const state = reactive({
            search:{key:""},
            info:{
                list:[],
                page:1,
                limit:10,
                count:0
            },
            show:{record:false,},
            contract:{
                contract_id:0,
                list:[],
                page:1,
                limit:10,
                count:0,
            }
        })
        getContractList(1,state.info.limit)

        function getContractList(page,limit){
            adoptModel.getAdoptContract(page,limit,state.search,res=>state.info = {limit,...res})
        }

        function showRecord(id){
            state.show.record = true
            state.contract.contract_id = id
            state.contract.list = []
            getContractRecord(1,state.contract.limit)
        }

        function getContractRecord(page,limit){
            let { contract_id } = state.contract
            adoptModel.getContractUser(page,limit,{contract_id},res=>{
                state.contract = {limit,contract_id,...res}
            })
        }

        function downloadContract(id){
            adoptModel.getContractOrder(id,res=>{})
        }

        return{
            ...toRefs(state),
            getContractList,
            showRecord,
            getContractRecord,
            downloadContract
        }
    },
}
</script>